import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import Dealer from '../Dealer'
import axiosInstance from '../../../../../../../Config/axios'
import { toast, Slide } from 'react-toastify'
import ToastContent from '../../../../../../../CommonComponent/Toast'
import CommunityCards from '../Dealer/CommunityCard'
import PokerActions from '../Actions'
import Player from '../Players'
import { gsap } from 'gsap';
import coins from "../../../../../../../assets/img/games/coins_transparent_yellow.png"
import QuitLobby from '../QuitLobby'
import CurrentPlayerCards from '../../CurrentPlayer/CurrentPlayerCards'
import hotToast from 'react-hot-toast'
import { formatAddress } from '../../../../../../../Config/function'
import Chat from '../../../../../../ChatingComponent/chatingComponent';

const Home = ({ showResult, gameInfo, socket, fetchGameInfo }) => {
    const usersRef = useRef([]);
    const navigate = useNavigate()
    let minimumBetAmount = gameInfo?.minimumBet|| 0
    const { gameAddress } = useParams()

    const userData = JSON.parse(localStorage.getItem('userData'))

    const [gameMoveStatus, setGameMoveStatus] = useState({ round: 0, buttons: [], userId: 0, maxBetAmount: minimumBetAmount, foldedPlayers: [], playersBalance: [], timer: 0, potMoney: 0, betStatus: 'betting', tableBetAmount: 0 })

    const [servedCards, setServedCards] = useState({
        'CommunityCards': [],
        'PlayerCards': [],
        'CommunityCards1': [],
        'CommunityCards2': [],
    })
    const [gameResult, setGameResult] = useState({})

    const serveCards = async () => {
        const allPlayers = gameInfo.players
        try {
            const bodyData = { players: allPlayers.map(item => item.id), gameAddress: gameAddress }

            const response = await axiosInstance.post('/api/game/poker/serve-cards', bodyData)

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent status='error' message={responseData.message} />, {
                    transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                })
            } else {
                const playersData = responseData.data['PlayerCards'];
                const communityData = responseData.data['CommunityCards'];
                const communityData1 = responseData.data['CommunityCards1'];
                const communityData2 = responseData.data['CommunityCards2'];

                const playerCards = allPlayers.map((player, index) => {

                    let user = playersData.filter(item => item['Name'] === player?.address)
                    user = user[0];
                    return { ...player, 'Hand': user['Hand'] }

                });
                if (responseData.data.gameResult && showResult) {
                    setGameResult(responseData.data.gameResult)
                }
                setServedCards({ 'CommunityCards': communityData, 'PlayerCards': playerCards, 'CommunityCards1': communityData1, 'CommunityCards2': communityData2 })
                // socket.emit('cardServed', { gameAddress, gameType: 'poker' })
            }

        } catch (error) {
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }

    const finishGame = async (winner) => {
        try {
            const response = await axiosInstance.post('/api/game/poker/finish-game', { gameAddress, userAddress: winner });

            const { data: responseData } = response;

            if (!responseData.status) {
                toast.error(<ToastContent status='error' message={response.data.message} />, {
                    transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                })
            } else {

            }
        } catch (error) {
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }

    // fetch player move
    const fetchPlayerMove = async () => {
        try {
            const bodyData = { gameAddress };
            const response = await axiosInstance.post('/api/game/poker/action-status', bodyData);

            const { data: responseData } = response;

            if (!responseData.status) {
                // toast.error(<ToastContent status='error' message={response.data.message} />, {
                //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                // })
            } else {
                if (responseData.data.winner) {
                    const winner = responseData.data.winner;

                    const userData = JSON.parse(localStorage.getItem('userData'));
                    if (userData?.address == winner) {
                        finishGame(winner);
                    }
                } else {
                    const newResponseData = responseData.data;
                    setGameMoveStatus(newResponseData)
                }

            }

        } catch (error) {
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }


    useEffect(() => {
        if (!socket) return
        socket.on('potUpdated', (data) => {
            const { isStarted, userId, action, amount } = data;

            if (action === 'blind' || action === 'big_blind') {
                const targetId = 'pot_money';
                const playerId = `player_${userId}`;
                animateFlowToPot(playerId, targetId);
            }

            if (isStarted) {
                fetchPlayerMove();
                serveCardsToPlayers();
                const totalPlayers = allPlayersCards.length;
                setTimeout(() => {
                    fetchPlayerMove();
                }, totalPlayers * 750 * 2);
            } else {
                fetchPlayerMove();
            }
        })

        socket.on("gameOver", (data) => {
            const { winner, gameAddress: responseGameAddress } = data;
            const userData = JSON.parse(localStorage.getItem('userData'));
            let message = 'Game Over! You have won the game'

            if (userData?.address == data?.winner) {
                message = 'Game Over! You have won the game'
            } else if (data?.winner) {
                let newWinner = formatAddress(data?.winner, 3)
                message = `Game Over! ${newWinner} has won the game`
            } else {
                message = 'Game Over!'
            }
            if (responseGameAddress === gameAddress) {
                fetchGameInfo();
                setTimeout(() => {
                    // toast.success(<ToastContent status='success' message={message} />, {
                    //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark',
                    //     toastId: 'gameOver'
                    // })
                    hotToast.success(message, { duration: 2000, position: 'bottom-center' })
                }, 2000);
            }
        });

        return () => {
            socket.off('potUpdated')
            socket.off('gameOver')
        }
    }, [servedCards])


    useEffect(() => {
        if (!gameAddress) return;
        if (servedCards.PlayerCards.length == 0) return;
    }, [servedCards])

    useEffect(() => {
        if (showResult) return;
        if (!gameAddress) return;
        if (servedCards.PlayerCards.length == 0) return;
    }, [servedCards])

    useEffect(() => {
        if (!gameAddress) return;
        serveCards()
        if (showResult) return
        fetchPlayerMove()
    }, [])


    let allPlayersCards = servedCards['PlayerCards']

    const currentUserPlayerCards = allPlayersCards.filter(player => player['address'] === userData?.address)
    const otherPlayers = allPlayersCards.filter(player => player['address'] !== userData?.address)

    const newPlayers = [...currentUserPlayerCards, ...otherPlayers].map((item, index) => {
        return { index, ...item }
    })

    let userTurn = servedCards['PlayerCards'].filter(player => player['userId'] === gameMoveStatus?.currentPlayer)




    if (userTurn.length > 0) {
        userTurn = userTurn[0]
    } else {
        userTurn = {}
    }

    let allCommunityCards = []

    if (gameMoveStatus.round === 0) {
        allCommunityCards = []
    } else if (gameMoveStatus.round == 1) {
        allCommunityCards = servedCards['CommunityCards']
    } else if (gameMoveStatus.round == 2) {
        allCommunityCards = servedCards['CommunityCards'].concat(servedCards['CommunityCards1'])
    } else if (gameMoveStatus.round == 3) {
        allCommunityCards = servedCards['CommunityCards'].concat(servedCards['CommunityCards1']).concat(servedCards['CommunityCards2'])
    } else {
        allCommunityCards = servedCards['CommunityCards'].concat(servedCards['CommunityCards1']).concat(servedCards['CommunityCards2'])
    }


    const showActionButtons = () => {

        if (gameMoveStatus?.userId === userData?.id && gameMoveStatus?.buttons?.length > 0) {
            return true;
        }

        return false;
    }

    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        // always show the user at the bottom and the rest of the players in a circular manner

        if (newPlayers.length == 2) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2'
            }
        } else if (newPlayers.length == 3) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 2) {
                return 'absolute flex flex-col items-center right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
            }
        } else if (newPlayers.length == 4) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }

            if (index == 2) {
                return 'absolute flex flex-col items-center left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2'
            }

            if (index == 3) {
                return 'absolute flex flex-col items-center right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
            }
        } else if (newPlayers.length == 5) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 2) {
                return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 3) {
                return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
            }

            if (index == 4) {
                return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'
            }
        } else if (newPlayers.length == 6) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 2) {
                return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 3) {
                return 'absolute flex flex-col items-center -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
            }

            if (index == 4) {
                return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
            }
            if (index == 5) {
                return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'
            }
        } else if (newPlayers.length == 7) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center bottom-0 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 2) {
                return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 '
            }

            if (index == 3) {
                return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 4) {
                return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
            }
            if (index == 5) {
                return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            }
            if (index == 6) {
                return 'absolute flex flex-col items-center bottom-0 right-0 transform translate-x-1/2 translate-y-1/4'
            }
        } else if (newPlayers.length == 8) {
            if (index == 0) {
                return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            }
            if (index == 1) {
                return 'absolute flex flex-col items-center bottom-0 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 2) {
                return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 '
            }

            if (index == 3) {
                return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
            }

            if (index == 4) {
                return 'absolute flex flex-col items-center -top-10 left-1/2 transform -translate-x-1/2'
            }
            if (index == 5) {
                return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
            }
            if (index == 6) {
                return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            }
            if (index == 7) {
                return 'absolute flex flex-col items-center bottom-0 right-0 transform translate-x-1/2 translate-y-1/4'
            }
        }




        return className
    }

    const serveCardsToPlayers = () => {
        const totalPlayers = allPlayersCards.length;
        const dealer = document.getElementById('dealer_target');
        for (let round = 0; round < 2; round++) {
            allPlayersCards.forEach((_, index) => {
                setTimeout(() => {
                    const card = document.createElement('div');
                    card.className = 'card h-16 w-12 bg-gradient-to-r from-red-400 border-2 border-gray-400 to-red-400 rounded-lg shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
                    dealer.appendChild(card);

                    const dealerRect = dealer.getBoundingClientRect();
                    const userIndex = index % totalPlayers;
                    if (!usersRef.current[userIndex]) return;
                    const userRect = usersRef.current[userIndex].getBoundingClientRect();
                    const cardRect = card.getBoundingClientRect();

                    const x = userRect.left + (userRect.width - cardRect.width) / 2 - dealerRect.left + window.scrollX;
                    const y = userRect.top + (userRect.height - cardRect.height) / 2 - dealerRect.top + window.scrollY;

                    gsap.to(card, {
                        duration: 1,
                        x: x,
                        y: y,
                        ease: "power2.inOut",
                        onComplete: () => {
                            card.remove();
                        }
                    });
                }, round * totalPlayers * 500 + index * 500); // Adjust delay for each round and player
            });
        }
    };

    const animateFlowToPot = (playerId, targetId) => {
        const playerElement = document.getElementById(playerId);
        const targetElement = document.getElementById(targetId);

        if (!playerElement || !targetElement) return;

        const playerRect = playerElement.getBoundingClientRect();
        const targetRect = targetElement.getBoundingClientRect();

        const card = document.createElement('img');
        card.className = 'card h-12 w-12 absolute rounded-full rounded-circle';
        card.src = coins;
        card.style.top = `${playerRect.top + window.scrollY}px`;
        card.style.left = `${playerRect.left + window.scrollX}px`;
        document.body.appendChild(card);

        const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
        const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

        gsap.to(card, {
            duration: 0.7,
            x: x,
            y: y,
            onComplete: () => {
                card.remove();
            }
        });
    };

    const handleQuitLobby = () => {
        navigate('/all-game-page')
    }

    const showCurrentPlayerCards = () => {
        return true;
    }

  const chatingComponentMemo = useMemo(() => <Chat userId={userData?.id}  roomId={gameAddress} />, [])


    return (
        <>
        {chatingComponentMemo}

            <div className='relative rounded-lg px-5 py-2 block mx-auto h-screen  md:w-[80%] w-[100%]'>
                <QuitLobby handleQuitLobby={handleQuitLobby} />

                <div className=" poker_table container px-5 h-2/3 rounded-lg shadow-lg top-1/2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">
                    <div className=" p-3 border-2 shadow-in h-[80%] w-full bg-green-900  rounded-full"
                    >
                        <div className=" relative gap-4 p-4 border-2 h-full w-full  rounded-full bg-gradient-to-r from-emerald-800 to-emerald-900">
                            <div id='dealer_target' className='absolute top-1/2 left-1/2 transform -translate-x-1/4 -translate-y-1/4'>

                            </div>
                            <div id='pot_money' className='absolute top-1/4 left-1/2 transform -translate-x-1/4 -translate-y-1/4'>
                                <Dealer pot={gameMoveStatus?.potMoney || 0} cards={allCommunityCards} />
                            </div>

                            <div className='absolute w-1/2 block mx-auto top-1/2 left-0 transform translate-x-1/2 -translate-y-1/4'>
                                <CommunityCards showResult={showResult} card={allCommunityCards} gameResult={gameResult} />
                            </div>

                            {newPlayers.map((player, index) => {
                                return (
                                    <div id={`player_${player?.userId}`} className={`${getClassName(index, player.userId)} ${showResult && (gameResult.winnerAddress == player.address) && 'poker_winner_player z-10'}`} ref={el => usersRef.current[index] = el}>
                                        <Player showResult={showResult} index={index} showDealer={player.userId === gameInfo?.dealerUserId} socket={socket} key={index} player={player} gameMoveStatus={gameMoveStatus} />
                                    </div>
                                )
                            })}

                        </div>
                        {<div animateFlowToPot={animateFlowToPot} className='absolute bottom-0 left-1/2 translate-y-1/2 -translate-x-1/2 w-full'>
                            <div className='flex flex-row items-center justify-center'>
                                <div className='flex justify-center items-center'>
                                    {showCurrentPlayerCards() && <CurrentPlayerCards gameMoveStatus={gameMoveStatus} player={currentUserPlayerCards[0] ? currentUserPlayerCards[0] : {}} />}
                                </div>
                                {showActionButtons() && <PokerActions animateFlowToPot={animateFlowToPot} minimumBetAmount={minimumBetAmount} gameMoveStatus={gameMoveStatus} socket={socket} />}
                            </div>
                        </div>}

                    </div>



                </div>

            </div>
        </>
    )
}

export default Home