import React from 'react'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import { formatAddress } from '../../../../../Config/function'
import { useSelector } from 'react-redux'

const Player = ({ player, folded, left }) => {

  const newPlayerCard = player.cards || []
  const userData = useSelector(state => state.auth.userData)

  const RenderGameAmount = ({ player }) => {
    let totalAmount = player.totalAmount
    let type = player.type


    let amount = player.amount
    let restAmount = player.restAmount
    
    amount = amount.toFixed(2)
    restAmount = restAmount.toFixed(2)

    amount = Number(amount)
    restAmount = Number(restAmount)

    let playerAmount = restAmount - amount

    playerAmount = Math.abs(playerAmount)

    playerAmount = playerAmount.toFixed(2)


    if (type == 'withdrawal') {
      return <span className='text-xs text-red-500'>- {totalAmount} Sol</span>
    }


    if (player.userId == userData.id) {
      return <span className='text-xs text-green-500'>+ ({totalAmount} + {playerAmount}) Sol</span>
    }


    if (type == 'deposit') {
      return <span className='text-xs text-green-500'>+ {totalAmount} Sol</span>
    }

    return null
  }

  return (
    <div className={`hi-lo-player flex items-center flex-col	`}>


      <div className='relative cards flex flex-row items-center justify-between gap-2 mb-0 '>
        {player.isFolded && <img src={FoldImage} alt='fold' className='w-auto h-16' />}
        {player.isLeft && !player.isFolded && <img src={LeftImage} alt='left' className='w-auto h-16' />}
        {/* {!player.isLeft && !player.isFolded && <img src={player.profileUrl} alt='left' className='w-16 h-16 rounded-full border-2 border-white p-1' />} */}
      </div>
      <div className={`flex flex-row justify-between items-center`}>
        <div className={`flex flex-row items-center justify-between bg-black font-extrabold px-2 text-center border-2 rounded-md `}>
        {<img src={player.profileUrl} alt='left' className='w-12 h-12 rounded-full border-2 border-white p-1' />}
          <div className='flex flex-col items-start justify-center  ml-2'>

          <div className='name text-white mb-0 text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}
           </div>
           <RenderGameAmount player={player} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Player
