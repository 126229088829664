import React, { useContext, useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { StakingApp } from '../../Authentication/Context/StakingApp';
import axiosInstance from '../../../Config/axios';
import { Slide, toast } from 'react-toastify';
import Toast from "../../../CommonComponent/Toast";

const WithdrawalPage = () => {
  const [selectedToken, setSelectedToken] = useState('SOL');
  const [selectedChain, setSelectedChain] = useState('SOL');
  const [withdrawalAddress, setWithdrawalAddress] = useState('');
  const [withdrawalAmount, setWithdrawalAmount] = useState('');
  const [minWithdrawalAmount, setMinWithdrawalAmount] = useState(0.1);
  const [maxWithdrawalAmount, setMaxWithdrawalAmount] = useState(100);
  const [showWarning, setShowWarning] = useState(false);
  const [isWithdrawalPending, setIsWithdrawalPending] = useState(false);
  const [withdrawalHistory, setWithdrawalHistory] = useState([]);

  const { asset } = useContext(StakingApp);
  console.log(asset, "fetching asset");

  useEffect(() => {
    const fetchWithdrawalHistory = async () => {
      try {
        const response = await axiosInstance.post('/withdrawalhistory');
        setWithdrawalHistory(response.data.message);
      } catch (err) {
        console.error(err);
      }
    };
    fetchWithdrawalHistory();
  }, []);

  const handleTokenSelect = (tokenKey) => {
    setSelectedToken(tokenKey);
    // Simulate fetching minimum and maximum withdrawal amounts based on token
    switch (tokenKey) {
      case 'BNB':
        setMinWithdrawalAmount(0.2);
        setMaxWithdrawalAmount(50);
        break;
      case 'ETH':
        setMinWithdrawalAmount(0.1);
        setMaxWithdrawalAmount(80);
        break;
      case 'SOL':
        setMinWithdrawalAmount(0.1);
        setMaxWithdrawalAmount(100);
        break;
      case 'USDT':
        setMinWithdrawalAmount(10);
        setMaxWithdrawalAmount(5000);
        break;
      default:
        setMinWithdrawalAmount(0.1);
        setMaxWithdrawalAmount(100);
    }
  };

  const withdrwalrRequest = async (assetId) => {
    try {
      const response = await axiosInstance.post("/withdrawalrequest", {
        amount: withdrawalAmount,
        assets: assetId,
        walletAddress: withdrawalAddress,
      });
      if (response.status) {
        toast.success(<Toast message={"Withdrawal requested submitted successfully"} status="success" />, {
          position: 'bottom-center', theme: 'dark', autoClose: 2000, transition: Slide
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChainSelect = (chain) => {
    setSelectedChain(chain);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check if withdrawal address is valid (basic validation for demonstration)
    if (withdrawalAddress.trim() === '') {
      setShowWarning(true);
      return;
    }
    // Check if withdrawal amount is within limits
    if (withdrawalAmount < minWithdrawalAmount || withdrawalAmount > maxWithdrawalAmount) {
      setShowWarning(true);
      return;
    }

    // Find the selected token object to get its ID
    const selectedTokenObject = asset.find(token => token.key === selectedToken);
    if (!selectedTokenObject) {
      setShowWarning(true);
      return;
    }

    // Simulate withdrawal process with a 30-minute delay for demonstration
    setIsWithdrawalPending(true);
    await withdrwalrRequest(selectedTokenObject.id);
    setIsWithdrawalPending(false);
    setWithdrawalAmount('');
  };

  return (
    <div className=" overflow-auto h-[100%] flex justify-center p-2 gap-5" >
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg  "
      >
        <h2 className="text-white text-xl mb-6">Withdrawal</h2>
        <form onSubmit={handleSubmit}>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            {/* Withdrawal Details */}
            <div>
              <div className="mb-4">
                <label htmlFor="token" className="text-white block mb-2">Select Token</label>
                <div className="flex items-center flex-wrap gap-4">
                  {asset?.map((token) => (
                    <motion.button
                      key={token.key}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className={`flex items-center p-2 gap-2 rounded-md cursor-pointer justify-center shadow-md ${selectedToken === token.key ? 'bg-green-600' : 'bg-gray-700'}`}
                      onClick={() => handleTokenSelect(token.key)}
                      type="button"
                    >
                      <img src={''} alt={token.key} className="h-8 w-8 mr-2" />
                      <span className="text-white font-medium">{token.key}</span>
                    </motion.button>
                  ))}
                </div>
              </div>

              <div className="mb-4">
                <label htmlFor="address" className="text-white block mb-2">Withdrawal Address</label>
                <input
                  type="text"
                  id="address"
                  value={withdrawalAddress}
                  onChange={(e) => setWithdrawalAddress(e.target.value)}
                  className="bg-gray-800 text-white py-2 px-4 rounded-md w-full"
                  placeholder="Enter withdrawal address"
                  required
                />
              </div>

              <div className="mb-4">
                <label htmlFor="amount" className="text-white block mb-2">Withdrawal Amount</label>
                <input
                  type="number"
                  id="amount"
                  value={withdrawalAmount}
                  onChange={(e) => setWithdrawalAmount(e.target.value)}
                  className="bg-gray-800 text-white py-2 px-4 rounded-md w-full"
                  placeholder={`Enter amount (${minWithdrawalAmount} - ${maxWithdrawalAmount})`}
                  required
                />
              </div>
              {showWarning && (
                <p className="text-red-600 text-sm mb-4">Please enter a valid withdrawal address and amount.</p>
              )}
            </div>

            {/* Withdrawal Limits */}
            <div className="bg-gray-800 p-6 rounded-md">
              <h3 className="text-white text-lg mb-4">Withdrawal Limits</h3>
              <div className="flex flex-col gap-4">
                <div>
                  <span className="text-white">Minimum Withdrawal Amount:</span>
                  <span className="text-green-500 ml-2">{minWithdrawalAmount} {selectedToken}</span>
                </div>
                <div>
                  <span className="text-white">Maximum Withdrawal Amount:</span>
                  <span className="text-red-500 ml-2">{maxWithdrawalAmount} {selectedToken}</span>
                </div>
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={`bg-green-600 hover:bg-green-700 text-white py-4 px-8 rounded-lg mt-8 mx-auto block shadow-md ${isWithdrawalPending ? 'cursor-not-allowed opacity-50' : ''}`}
            type="submit"
            disabled={isWithdrawalPending}
          >
            {isWithdrawalPending ? 'Withdrawal Pending...' : 'Withdraw'}
          </motion.button>
        </form>
        <div className="bg-gray-600 text-white p-4 mt-4 rounded-lg">
          <p className="font-medium">Notice:</p>
          <p className="text-sm mt-2">
            Please ensure you enter a valid blockchain address for withdrawal. Entering an incorrect address may result in permanent loss of your funds.
            Withdrawal may take up to 30 minutes to process.
          </p>
        </div>
      </motion.div>

      {/* Withdrawal History */}
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3 }}
        className="bg-[#1d1122] p-6 rounded-lg shadow-lg "
      >
        <h2 className="text-white text-xl mb-6">Withdrawal History</h2>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-gray-800">
            <thead>
              <tr>
                <th className="px-4 py-2 text-left text-white">Asset Id</th>
                <th className="px-4 py-2 text-left text-white">Amount</th>
                <th className="px-4 py-2 text-left text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              {withdrawalHistory.length === 0 ? (
                <tr>
                  <td colSpan="3" className="px-4 py-2 text-center text-gray-400">No withdrawal history available</td>
                </tr>
              ) : (
                withdrawalHistory.map((withdrawal) => (
                  <tr key={withdrawal.id}>
                    <td className="px-4 py-2 text-white">{withdrawal.assetId}</td>
                    <td className="px-4 py-2 text-white">{withdrawal.amount}</td>
                    <td className="px-4 py-2 text-white">{withdrawal.status}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </motion.div>
    </div>
  );
};

export default WithdrawalPage;
