import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import { useSelector } from 'react-redux';
import SolToUSD from '../../../../../CommonComponent/SolToUSD';
// import De

const Player = ({ player }) => {

    const userData = useSelector((state) => state.auth.userData);

    return (
        <div className={`relative flex items-center bg-green-900 px-2 py-1 border-2  rounded-lg shadow-lg space-x-4`}>

            <div className="relative">
                <img src={player?.profileUrl} className="w-12 h-12 rounded-full border-white border" />
            </div>

            <div className='flex flex-col'>
                <div className="text-white font-semibold text-xs">
                    {/* {userData?.id === player.userId ? 'You' : formatAddress(player.address, 6)} */}
                    {player.userName ? player.userName : userData?.id === player.userId ? 'You' : formatAddress(player.address, 6)}
                </div>

                <div className="text-white text-xs font-bold">
                    {userData?.id === player.userId ? Number(player?.amount).toFixed(2) : '***'} Sol
                   {userData?.id === player.userId ? 
                   <span className='ml-1'>
                   (<SolToUSD amount={player?.amount} symbol={"USD"}/>)
                   </span>

                    : "(*** USD)"}   
                
                </div>
            </div>


        </div>
    )
}

export default Player