import React from 'react'

const HiddenCard = () => {
  return (
    <div id='high_low_hidden_card' className='flipped hi_lo_hidded_card w-20 h-28 bg-red-300 rounded-lg'>
    </div>
  )
}

export default HiddenCard
