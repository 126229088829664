import React, { useState, useEffect } from 'react'
import Fold from "../../../../../../assets/img/fold.png"
import Dealer from "../../../../../../assets/img/dealer.png"
import CardHeart from '../CardHeart'
import CardDiamond from '../CardDiamond'
import CardSpade from '../CardSpade'
import CardClub from '../CardClub'
import { useSelector } from 'react-redux'
import { formatAddress, isEmpty } from '../../../../../../Config/function'

const CurrentPlayer = ({ gameResult, showResult, player, gameMoveStatus, isPlayerFold, showDealer, socket }) => {


    const userData = useSelector(state => state.auth.userData)
    const solPrice = useSelector(state => state.auth.solPrice)
    const [playerMoveInfo, setPlayerMoveInfo] = useState(null)
    const cards = player['Hand'] || []

    useEffect(() => {
        if (showResult) return;
        if (!socket) return;

        socket.on('userMove', (data) => {
            setPlayerMoveInfo(data)
            setTimeout(() => {
                setPlayerMoveInfo(null)
            }, 3000)
        })

        return () => {
            socket.off('userMove')
        }

    }, [])

    const highLightCard = (card) => {
        if (showResult) return false;
        if (gameResult) return false;
        return 'scale-150 mx-4'
    }

    const RenderMove = ({ amount, action }) => {

        if (action === 'fold') {
            return <span>{action} </span>
        }
        if (action === 'check') {
            return <span>{action} </span>
        }

        if (action === 'raise') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'call') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'allin') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'blind') {
            return <span>Blind {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'big_blind') {
            return <span>Big Blind {Number(amount).toFixed(2)} Sol</span>
        }
        if (action === 'bet') {
            return <span>Bet {Number(amount).toFixed(2)} Sol</span>
        }

        return null

    }


    const RenderCard = ({ card, className }) => {
        const suit = card['Suit']
        const rank = card['Rank']

        if (suit === 'H') {
            return <div className={`${highLightCard(card)} w-16 h-20 mx-2`}><CardHeart playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'D') {
            return <div className={`${highLightCard(card)} w-16 h-20  mx-2`}><CardDiamond playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'S') {
            return <div className={`${highLightCard(card)} w-16 h-20  mx-2`}><CardSpade playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'C') {
            return <div className={`${highLightCard(card)} w-16 h-20  mx-2`}><CardClub playerCard={true} rank={rank} className={className} /></div>
        }

        return null
    }

    const canSeeCards = () => {
        if (!showResult) return false
        return true
    }


    const RenderDealer = () => {
        return <div>
            <img className='absolute -right-5 -bottom-5 h-8 w-8 shadow-2xl rounded-circle z-10' src={Dealer} alt='dealer' />
        </div>
    }
    const RenderBlind = () => {
        return <div className='absolute -top-1/4 -left-5 flex justify-center items-center text-white font-bold bg-red-800  text-xs h-8 w-auto px-2 shadow-2xl rounded-md z-10'>
            Small Blind
        </div>
    }
    const RenderBigBlind = () => {
        return <div className='absolute -top-1/4 -left-5 flex justify-center items-center text-white font-bold bg-red-800  text-xs h-8 w-auto px-2 shadow-2xl rounded-md z-10'>
            Big Blind
        </div>
    }

    const RenderTimer = () => {
        const [timer, setTimer] = useState(gameMoveStatus?.timer || 30)

        useEffect(() => {
            const interval = setInterval(() => {
                setTimer(timer => timer - 1)
            }, 1000)

            if (timer === 0) {
                clearInterval(interval)
            }

            return () => {
                clearInterval(interval)
            }
        }, [timer])

        if (showResult) return null
        // if (gameMoveStatus?.round === 0 && ['blind', 'big_blind'].includes(player.userRole) && gameMoveStatus?.userId == userData?.id) return null

        if (!gameMoveStatus.timer) return null

        if (gameMoveStatus?.userId !== player?.userId) return null

        return <div className='absolute -bottom-4 left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white font-bold bg-red-800 text-xs h-8 w-8 shadow-2xl rounded-circle z-10 fade-in-up'>
            {timer}
        </div>
    }

    const playersBalance = gameMoveStatus?.playersBalance || []

    const myBalance = playersBalance.find(p => p.userId === userData?.id)

    const highlightPlayer = () => {
        if (!showResult) return false;
        if (!gameResult) return false;
        const { winner, winnerCombination } = gameResult;
        if (!winnerCombination) return false;
        if (!winner) return false;
        const isWinner = (winner === player?.address);
        return !isWinner ? 'bg_transparent_dark' : false;
    }
    const RenderUsd = ({ amount }) => {
        if (!solPrice) return null
        if (!amount) return null
        let newAmount = Number(amount)

        return <span className='text-xs text-white font-bold ms-2'>({(solPrice * newAmount).toFixed(2)} USD)</span>
    }

    return (
        <div>
            <div className={`${highlightPlayer()} relative flex items-center bg-green-900 px-1 py-1 border-2  rounded-lg shadow-lg space-x-4 ${isPlayerFold(player?.userId) ? 'border-white' : ((gameMoveStatus?.userId === player?.userId) && gameMoveStatus.betStatus === 'playing') ? 'border-red-800 border-4' : 'border-white'}`}>

                {showDealer && <RenderDealer />}
                {player?.userRole == 'blind' && <RenderBlind />}
                {player?.userRole == 'big_blind' && <RenderBigBlind />}

                {!showResult && !isEmpty(gameMoveStatus.timer) && (gameMoveStatus?.userId === player?.userId) && (gameMoveStatus.betStatus === 'playing') && <RenderTimer />}

                <div className="relative">
                    <img src={player?.profileUrl} className="w-12 h-12 rounded-full border-white border" />
                </div>

                <div>
                    <div className="text-white font-semibold text-xs">
                        You
                    </div>
                    <div className="text-blue-300 text-xs">{myBalance?.amount} Sol
                        <RenderUsd amount={myBalance?.amount} />
                    </div>
                </div>
                <div className="absolute ml-auto -top-1/2 right-1/2">
                    <div className={`relative {${!isPlayerFold(player?.userId) && 'w-10 h-16'}`}>
                        {!isPlayerFold(player?.userId) ? canSeeCards() ? <div className={`w-full absolute -top-10 left-1/2 -translate-x-1/2 flex justify-center `}>
                            {cards.map((card, index) => {
                                return <RenderCard key={index} card={card} className={'w-16 h-20'} />
                            })}
                        </div> : <div className='flex flex-row'>
                            <div className="absolute top-0 left-0 w-full h-full bg-red-300 rounded-md shadow-md"></div>
                            <div className="absolute top-2 left-2 w-full h-full bg-pink-300 rounded-md shadow-md"></div>
                        </div> : <div className='carBox01 flex justify-center w-full'>
                            {<img src={Fold} alt="card" style={{ height: '50px', }} />}
                        </div>}
                    </div>
                </div>
                {(playerMoveInfo?.userId == player?.userId) && <div class="absolute -bottom-3 px-3 text-xs left-1/2 transform -translate-x-1/2 scale-up text-capitalize capitalize text-black text-center mt-3 bg-white border border-black rounded-xl ">
                    <p class="text-black text-center font-bold"><RenderMove amount={playerMoveInfo?.amount} action={playerMoveInfo?.action} /></p>
                </div>}
            </div>
        </div>
    )
}

export default CurrentPlayer