import React, { useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import FitScreenIcon from '@mui/icons-material/FitScreen';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import LeaveHilowModal from '../../../../../CommonComponent/LeaveHiLoModal';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useParams } from 'react-router-dom';
import hotToast from 'react-hot-toast';

const MenuActions = ({ isInGame, handleJoinGame, enableFullScreen, handleLeaveGame, gameStatus }) => {

    const [showLeaveModal, setShowLeaveModal] = useState(false)

    const { gameAddress } = useParams()

    const handleToggleLeaveModal = () => {
        setShowLeaveModal(!showLeaveModal)
    }

    let message = 'Are you sure you want to leave the game?'

    if (gameStatus === 'pending') {
        message = 'Are you sure you want to cancel the game?'
    } else if (gameStatus === 'started') {
        message = 'Are you sure you want to leave the game? You will lose the game if you leave now.'
    } else if (gameStatus === 'completed') {
        message = 'Are you sure you want to leave the game?'
    }

    const handleCopy = () => {
        hotToast.success('Address copied to clipboard', { duration: 2000, position: 'bottom-center' })
    }

    return (
        <>
            <div className='flex container flex-row items-center justify-between w-full p-2'>
                <div className=''>
                    {<CopyToClipboard text={gameAddress} onCopy={handleCopy}>
                        <button className='bg-blue-800 mr-2 text-white btn text-center py-2.5 text-xs'>
                            <ContentCopyIcon className='mr-2' fontSize='14' /> Copy Address
                        </button>
                    </CopyToClipboard>}
                </div>
                <div className='mr-2'>
                    {!(isInGame) && (gameStatus !== 'completed') && <button onClick={handleJoinGame} className=' bg-blue-800 mr-2 text-white btn text-center '>
                        Join Game
                    </button>}
                    {isInGame && <button onClick={handleToggleLeaveModal} className=' bg-red-800 mr-2 text-white btn text-center '>
                        <CloseIcon fontSize='14' />
                    </button>}
                    <button className='bg-green-600 mr-2 font-bold inset-2 shadow text-white btn text-center'>
                        <VolumeUpIcon fontSize='14' />
                    </button>
                    <button onClick={enableFullScreen} className='bg-green-600 font-bold inset-2 shadow text-white btn text-center'>
                        <FitScreenIcon fontSize='14' />
                    </button>
                </div>
            </div>
            {showLeaveModal && <LeaveHilowModal show={showLeaveModal} handleClose={handleToggleLeaveModal} handleSubmit={handleLeaveGame} text={message} />}
        </>

    )
}

export default MenuActions
