import React from 'react'
import { GiClubs } from "react-icons/gi";


const ClubCard = ({ Rank, textSize, fontSize }) => {
    if (!Rank) return null

    return (
        <div className='relative w-full h-full'>
            <div className='absolute top-0.5 left-1 flex flex-col font-extrabold text-2xl'>
                <span className={`text-black font-extrabold ${textSize ? textSize : "text-sm"} mb-0`}>{Rank}</span>
            </div>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col font-extrabold text-2xl'>
                <GiClubs className='text-black' fontSize={fontSize || 20} />
            </div>
            <div className='absolute bottom-0.5 right-1 flex flex-col font-extrabold text-2xl'>
                <span className={`text-black font-extrabold ${textSize ? textSize : "text-sm"} mb-0`}>{Rank}</span>
            </div>
        </div>
    )

}

export default ClubCard
