import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import Dealer from "../../../../../assets/img/dealer.png"

import Card from "../Common/Cards/index"
const Player = ({ showDealer, selectedCards, handleToggleCard, player, index, totalPlayers, gameStatus, foldedPlayers, leftPlayers, userBalances, playerCards, currentPlayer, timer }) => {

  const userData = useSelector(state => state.auth.userData)

  const getCardsClassName = () => {

    if (totalPlayers === 2) {
      if (index === 0) return "-top-6 left-1/2 transform -translate-x-1/2"
      if (index === 1) return "-bottom-10"
    }
    if (totalPlayers === 3) {
      if (index === 0) return "-top-6 left-1/2 transform -translate-x-1/2"
      if (index === 1) return "left-32 top-1/2"
      if (index === 2) return "right-32 top-1/2"
    }

    if (totalPlayers === 4) {
      if (index === 0) return "-top-6 left-1/2 transform -translate-x-1/2"
      if (index === 1) return "left-32 top-1/2"
      if (index === 2) return "-bottom-10"
      if (index === 3) return "right-32 top-1/2"
    }

    if (totalPlayers === 5) {
      if (index === 0) return "-top-6 left-1/2 transform -translate-x-1/2"
      if (index === 1) return " left-28 "
      if (index === 2) return "left-28 bottom-0"
      if (index === 3) return "  right-28 bottom-0"
      if (index === 4) return " right-28"
    }
    if (totalPlayers === 6) {
      if (index === 0) return "-top-6 left-1/2 transform -translate-x-1/2"
      if (index === 1) return " left-28 "
      if (index === 2) return "left-28 bottom-0"
      if (index === 3) return " -bottom-12"
      if (index === 4) return " right-28 bottom-0"
      if (index === 5) return " right-28"
    }
  }

  const currentPlayerCards = playerCards?.find(playerCard => playerCard.userId === player.userId)?.cards || []

  const playerBalance = userBalances?.find(userBalance => userBalance?.userId === player.userId) || {}


  const isFolded = foldedPlayers.includes(player.userId)
  const isLeft = leftPlayers.includes(player.userId)
  const isPlayerMove = ((player?.userId == currentPlayer) && gameStatus !== 'starting')

  const RenderDealer = () => {
    return <div>
      <img className='absolute -right-2 -bottom-5 h-8 w-8 shadow-2xl rounded-circle z-10 ' src={Dealer} alt='dealer' />
    </div>
  }

  const RenderBlind = () => {
    return <div className=' text-xs flex justify-center items-center text-white font-bold ml-2 bg-red-500  px-0.5 shadow-2xl rounded-sm z-20 '>
      (Small Blind)
    </div>
  }
  const RenderBigBlind = () => {
    return <div className=' text-xs flex justify-center items-center text-white font-bold ml-2 bg-red-500 w-full px-0.5 shadow-2xl rounded-sm z-20'>
      (Big Blind)
    </div>
  }



  const isTimer = timer > 0

  const RenderTimer = () => {
    const [timeLeft, setTimeLeft] = useState(timer)

    useEffect(() => {
      if (!isTimer) return;

      const interval = setInterval(() => {
        setTimeLeft(timeLeft - 1)
      }, 1000)

      return () => {
        clearInterval(interval)
      }
    }, [timeLeft])

    if (gameStatus === 'starting') return null;
    if (timeLeft <= 0) return null;

    return <span className='hi-lo-player__profile absolute rounded-full h-8 w-8 -bottom-4 border-red-800 font-extrabold flex items-center justify-center bg-red-800 text-white shadow-2xl'>
      <span className='text-xs'>{timeLeft}</span>
    </span>
  }


  const timerMemo = React.useMemo(() => <RenderTimer />, [timer])

  return (
    <>
      <div className='relative five_card_player flex items-center flex-col'>
        {isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-36  ${player.active && "active"}`} />}
        {!isFolded && isLeft && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile h-24 w-36  ${player.active && "active"}`} />}
        {!isFolded && !isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${player.active && "active"}`} />}


        <div className={`${isPlayerMove && " border-2 border-white py-2 px-3 bg-green-400 rounded-full relative"}`}>

          <div className='relative flex flex-col items-center justify-center bg-black font-extrabold px-3 text-center border-2 rounded-md'>
            
            {showDealer && <RenderDealer />}

            {isTimer && (currentPlayer === player.userId) && timerMemo}
            <div className='name text-white mb-1 text-xs flex flex-row'>{
              player.userName ? player.userName :
                formatAddress(player.address, 3)

            }
              <span>
                {player.userRole === 'blind' && <RenderBlind />}
                {player.userRole === 'big_blind' && <RenderBigBlind />}
              </span>
            </div>



            {player?.userId === userData?.id ? <div className='balance text-white text-xs'>({ Number(playerBalance.amount).toFixed(2)}) Sol</div> : null}
          </div>
        </div>

       


        {!isFolded && !isLeft && (player.userId === userData?.id) && <div className={`flex items-center justify-center absolute -top-20 gap-0.5 `}>
          {gameStatus !== 'starting' && currentPlayerCards.map((card, index) => (
            <span id={`player_card_${player.userId}_${index}`} role='button' onClick={() => handleToggleCard(index)} className={`fade-in-up ${selectedCards.includes(index) && 'scale-125'}`} >
              <Card textSize={'text-xs'} fontSize={12} card={card} className={"h-16 w-10 rounded-sm border-2"} />
            </span>
          ))}
        </div>}


      </div>


    </>
  )
}

export default Player
