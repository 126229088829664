import React, { useState, useEffect } from 'react'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { toast, Slide } from 'react-toastify'
import hotToast from 'react-hot-toast'
import 'react-toastify/dist/ReactToastify.css';
import ToastContent from '../../../../../../../CommonComponent/Toast';
import axiosInstance from '../../../../../../../Config/axios';
import { useParams } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useFormik } from 'formik';
import * as Yup from 'yup'
import FireSvg from "../../../../../../../assets/img/games/fire.svg";
const PokerActions = ({ gameMoveStatus, animateFlowToPot }) => {

    const userData = JSON.parse(localStorage.getItem('userData'))
    const [isLoaded, setIsLoaded] = useState(false)
    const playersBalance = gameMoveStatus?.playersBalance || []
    const myBalance = playersBalance.find(player => player.userId = userData.id)?.amount || 0
    const [raiseAmount, setRaiseAmount] = useState(0.1)

    const { gameAddress } = useParams()


    const maxBetAmount = gameMoveStatus?.maxBetAmount
    const buttons = gameMoveStatus?.buttons

    const tableBetAmount = gameMoveStatus?.tableBetAmount

    useEffect(() => {
        if (gameMoveStatus?.userId === userData.id) {
            setIsLoaded(false)
        }
    }, [gameMoveStatus])


    const handleFold = async () => {
        try {
            setIsLoaded(true)
            const bodyData = { action: 'fold', amount: 0, gameAddress: gameAddress, round: gameMoveStatus.round }

            const response = await axiosInstance.post('/api/game/poker/move', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                setIsLoaded(false)
                // toast.error(<ToastContent status='error' message={responseData.message} />, {
                //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                // })
                hotToast.error(responseData.message || 'Error', { duration: 2000 })
            } else {
                // socket.emit('game-move', { gameAddress: gameAddress })
            }

        } catch (error) {
            setIsLoaded(false)
            hotToast.error(error.message || 'Error', { duration: 2000 })

            // toast.error(<ToastContent status='error' message={error.message} />, {
            //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            // })
        }
    }

    const handleCall = async () => {
        setIsLoaded(true)
        try {
            const bodyData = { action: 'call', amount: Number(maxBetAmount).toFixed(2), gameAddress: gameAddress, round: gameMoveStatus.round }
            const response = await axiosInstance.post('/api/game/poker/move', bodyData)
            const { data: responseData } = response

            if (!responseData.status) {
                setIsLoaded(false)
                // toast.error(<ToastContent status='error' message={responseData.message} />, {
                //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                // })
                hotToast.error(responseData.message || 'Error', { duration: 2000 })
            } else {
                animateFlowToPot(`player_${userData.id}`, 'pot_money')
            }

        } catch (error) {
            setIsLoaded(false)
            hotToast.error(error.message || 'Error', { duration: 2000 })
            // toast.error(<ToastContent status='error' message={error.message} />, {
            //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            // })
        }
    }

    const handleCheck = async () => {
        try {
            setIsLoaded(true)
            const bodyData = { action: 'check', amount: 0, gameAddress: gameAddress, round: gameMoveStatus.round }

            const response = await axiosInstance.post('/api/game/poker/move', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                setIsLoaded(false)
                // toast.error(<ToastContent status='error' message={responseData.message} />, {
                //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                // })
                hotToast.error(responseData.message || 'Error', { duration: 2000 })
            } else {
                animateFlowToPot(`player_${userData.id}`, 'pot_money')
            }



        } catch (error) {
            setIsLoaded(false)
            hotToast.error(error.message || 'Error', { duration: 2000 })
           
        }
    }

    const handleRaise = async () => {
        try {
            let raisedAmount = Number(formik.values.raisedAmount)
            let maxBetAmount = Number(gameMoveStatus.maxBetAmount)
            if (raisedAmount <= maxBetAmount) return hotToast.error('Amount must be greater than bet amount', { duration: 2000 })
            setIsLoaded(true)
            const bodyData = { action: 'raise', amount: Number(formik.values.raisedAmount).toFixed(2), gameAddress: gameAddress, round: gameMoveStatus.round }

            const response = await axiosInstance.post('/api/game/poker/move', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                setIsLoaded(false)
                hotToast.error(responseData.message || 'Error', { duration: 2000 })
                
            } else {
                animateFlowToPot(`player_${userData.id}`, 'pot_money')
            }

        } catch (error) {
            setIsLoaded(false)
            hotToast.error(error.message || 'Error', { duration: 2000 })
           
        }
    }

    const handleBet = async () => {
        try {
            setIsLoaded(true)
            const bodyData = { action: 'bet', amount: 0.1, gameAddress: gameAddress, round: gameMoveStatus.round }

            const response = await axiosInstance.post('/api/game/poker/move', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                setIsLoaded(false)
                hotToast.error(responseData.message || 'Error', { duration: 2000 })
                // toast.error(<ToastContent status='error' message={responseData.message} />, {
                //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                // })
            } else {
                animateFlowToPot(`player_${userData.id}`, 'pot_money')
            }

        } catch (error) {
            setIsLoaded(false)
            hotToast.error(error.message || 'Error', { duration: 2000 })
            // toast.error(<ToastContent status='error' message={error.message} />, {
            //     transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            // })
        }
    }

    const handleAllIn = async () => {
        try {
            setIsLoaded(true)
            const bodyData = { action: 'allin', amount: myBalance, gameAddress: gameAddress, round: gameMoveStatus.round }

            const response = await axiosInstance.post('/api/game/poker/move', bodyData)

            const { data: responseData } = response

            if (!responseData.status) {
                setIsLoaded(false)
                toast.error(<ToastContent status='error' message={responseData.message} />, {
                    transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
                })
            } else {
                animateFlowToPot(`player_${userData.id}`, 'pot_money')
            }

        } catch (error) {
            setIsLoaded(false)
            toast.error(<ToastContent status='error' message={error.message} />, {
                transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
            })
        }
    }

    const handleAction = (action) => {
        if (action == 'fold') return handleFold()
        if (action == 'call') return handleCall()
        if (action == 'check') return handleCheck()
        if (action == 'raise') return handleRaise()
        if (action == 'bet') return handleBet()
        if (action == 'allin') return handleAllIn()
    }

    let raisedAmount = Number(maxBetAmount)
    if (maxBetAmount == 0) {
        raisedAmount = 0.1
    }
    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            raisedAmount: (Number(Number(raiseAmount).toFixed(2)) + Number(Number(raisedAmount).toFixed(2))).toFixed(2)
        },
        validationSchema: Yup.object({
            raisedAmount: Yup.number().required('Required').min(Number(raisedAmount), `Amount must be greater than ${Number(raisedAmount).toFixed(2)}`).max(myBalance, 'Insufficient balance')
        }),
        onSubmit: () => {
            handleAction('raise')
        }
    })

    if (isLoaded) return null

    if (gameMoveStatus.round == 4) return null

    const handleIncreaseAmount = () => {
        if (raiseAmount >= myBalance) return
        setRaiseAmount(raiseAmount + 0.1)
    }

    const handleDecreseAmount = () => {

        if (Number(raiseAmount).toFixed(2) <= 0.1) return
        setRaiseAmount(raiseAmount - 0.1)
    }

    let showAllIn = false

    let newMaxBetAmount = Number(maxBetAmount).toFixed(2)
    newMaxBetAmount = Number(newMaxBetAmount)

    let newMyBalance = Number(myBalance).toFixed(2)
    newMyBalance = Number(newMyBalance)

    if (newMyBalance > newMaxBetAmount) {
        showAllIn = true
    }

    let btnParentClassName = `call_action p-0 flex flex-row items-start justify-center rounded-lg gap-1`
    if (buttons.length == 2) {
        btnParentClassName = `call_action p-0 flex flex-col items-start justify-center rounded-lg gap-1`
    }

    return (

        <div className="flex flex-col justify-center space-x-4 p-1 w-full md:w-1/2">
            <div className={btnParentClassName}>
                {buttons.includes('Call') && <button onClick={() => handleAction('call')} className=" bg-blue-900 text-xs w-full py-2 px-3 rounded-lg mb-1 text-white">
                    Call - {Number(maxBetAmount).toFixed(2)} Sol
                </button>}
                {buttons.includes('Check') && <button onClick={() => handleAction('check')} className="bg-blue-800 text-xs w-full py-2 px-3 rounded-lg mb-1 text-white flex items-center justify-center">
                    Check 
                </button>}
                {buttons.includes('Fold') && <button onClick={() => handleAction('fold')} className="bg-red-800 text-xs py-2 rounded-lg text-white w-full flex items-center justify-center">
                    Fold 
                </button>}
                {buttons.includes('Bet') && <button onClick={() => handleAction('bet')} className="bg-blue-300 text-xs py-2 rounded-lg text-black w-full">
                    Bet (0.1 Sol) 
                </button>}
                {buttons.includes('AllIn') && <button onClick={() => handleAction('allin')} className="bg-transparent text-red-400 text-xs py-1.5 rounded-lg font-medium border-2 border-red-400 w-full flex items-center justify-center">
                    All-In <img src={FireSvg} className='w-4 h-4 ml-1' />
                </button>}
            </div>


            {buttons.includes('Raise') && <form onSubmit={formik.handleSubmit} className='raise_action mx-0 mt-2 gap-1 flex flex-row text-xs items-center justify-between rounded-lg w-full' >
                <div className='flex flex-col w-36'>
                    <input placeholder='Example: 0.30' type="number" name='raisedAmount' value={formik.values.raisedAmount} onChange={formik.handleChange} className=" py-2 px-3 rounded-lg  text-white bg-gray-600 border border-white" />
                </div>
                <button type='submit' disabled={formik.touched.raisedAmount && formik.errors.raisedAmount} className="disabled:cursor-not-allowed px-3 bg-green-800 border py-2 rounded-lg text-white w-full">
                    Raise {Number(formik.values.raisedAmount).toFixed(2)} Sol
                </button>
                <button type='button' onClick={handleIncreaseAmount} className="text-white text-xs h-full py-1 px-2 border bg-green-800 rounded-lg w-full">
                    <AddIcon />
                </button>
                <button type='button' onClick={handleDecreseAmount} className="text-white text-xs h-full py-1 px-2 border bg-green-800 rounded-lg w-full">
                    <RemoveIcon />
                </button>

            </form>}
            {formik.errors.raisedAmount && formik.touched.raisedAmount && <span className='text-danger text-xs'>{formik.errors.raisedAmount}</span>}



        </div>
    )
}

export default PokerActions