import React, { useEffect } from 'react'
import MenuActions from '../PlayGame/MenuActions'
import { useSelector } from 'react-redux'
import Player from './Player'
import { formatAddress, isEmpty } from '../../../../../Config/function'
import axiosInstance from '../../../../../Config/axios'
import { toast, Slide } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ToastContent from '../../../../../CommonComponent/Toast'
import { useNavigate } from 'react-router-dom'
import hotToast from 'react-hot-toast'
import PotMoney from '../PlayGame/PotMoney'
import Card from '../PlayGame/Cards/Common/Card'
import coins from '../../../../../assets/img/games/coins_yellow_transparent.png';
import { gsap } from 'gsap';
import { Flip } from 'gsap/Flip';
const PlayAgain = ({ gameInfo, fetchGameInfo }) => {
  gsap.registerPlugin(Flip);

    const userData = useSelector(state => state.auth.userData)
    const gameAddress = gameInfo?.gameAddress
    const navigate = useNavigate()


    let players = gameInfo?.result?.players
    const myProfile = players?.find(player => player.address === userData?.address)
    const otherPlayers = players.filter(player => player.address !== userData?.address)


    if (myProfile) {
        players = [myProfile, ...otherPlayers]
    } else {
        players = otherPlayers
    }




    const WaitingPlayer = () => {
        return null
    }

    const isInGame = !isEmpty(myProfile)


    const handleJoinGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/join', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                const data = { slug: 'highlow', gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
            }


        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (players.length == 1) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        }

        if (players.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (players.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (players.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (players.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/4 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/4 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-1/4 transform translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/4 right-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'

        } else if (players.length == 8) { }

        return className
    }

    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/highlow/leave', { gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('hilo_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }


    const gameResult = gameInfo?.result


    const GameResultComponent = () => {
        if (!gameResult) return null

        const { winner, pot, card, nextCard } = gameResult
        if (!winner) return null

        return (
            <div className='flex flex-col items-center justify-center'>
                {!isEmpty(pot) && <div className='flex items-center justify-center'>
                    <PotMoney pot={pot} />
                </div>}
                <span className='text-white font-bold text-2xl mt-0 mb-2'>Winner</span>
                <div className='hi-lo-player flex items-center justify-start flex-col	'>
                    {<img src={winner.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 hi_lo_player__profile_active`} />}
                    <span className='hi-lo-player__balance text-white font-bold mt-2 text-xs'>{winner.userName ? winner.userName : formatAddress(winner.address, 6)}</span>
                    <div className='winner_cards mt-2 flex flex-row justify-center items-center gap-2'>
                        {card && <Card className='h-14 w-9 rounded-sm' card={card} />}
                        {nextCard && <Card className='h-14 w-9 rounded-sm' card={nextCard} />}
                    </div>

                </div>
            </div>
        )
    }


  const animateFlowToPot = (playerId, targetId, isCard) => {
    const playerElement = document.getElementById(playerId);
    const targetElement = document.getElementById(targetId);
    if (!playerElement || !targetElement) return;

    const playerRect = playerElement.getBoundingClientRect();
    const targetRect = targetElement.getBoundingClientRect();

    // Create a card element (or image)
    let card = null

    if (isCard) {
      card = document.createElement('span');
      card.className = 'card h-20 w-14 bg-red-400 rounded-md border-2 border-white rounded-md shadow-lg absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2';
      card.style.top = `${playerRect.top + window.scrollY}px`;
      card.style.left = `${playerRect.left + window.scrollX}px`;
    } else {
      card = document.createElement('img');
      card.className = 'card h-12 w-12 bg-transparent absolute rounded-full rounded-circle ';
      card.src = coins;
      card.style.top = `${playerRect.top + window.scrollY}px`;
      card.style.left = `${playerRect.left + window.scrollX}px`;
    }

    document.getElementById('hilo_screen').appendChild(card);
    const x = targetRect.left + targetRect.width / 2 - playerRect.left - playerRect.width / 2;
    const y = targetRect.top + targetRect.height / 2 - playerRect.top - playerRect.height / 2;

    gsap.to(card, {
      duration: 1.3,
      x: x,
      y: y,
      onComplete: () => {
        card.remove();
      }
    });
  };

  const showCoinAnimation = () => {
    try {
      if (!gameResult) return
      const { players } = gameResult
      if (!players) return

      const winnerPlayer = gameResult?.winner ? [gameResult.winner] : []
      const otherPlayers = players.filter(player => player.userId !== winnerPlayer[0]?.userId)
      for (let i = 0; i < otherPlayers.length; i++) {
        let player = otherPlayers[i]
        for (let j = 0; j < winnerPlayer.length; j++) {
          let winner  = winnerPlayer[j]
          animateFlowToPot(`high_low_result_player_${player.userId}`, `high_low_result_player_${winner.userId}`, false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    showCoinAnimation()
  }, [gameInfo])

    return (
        <div id='hilo_screen' className='game flex flex-col items-center h-full'>
            <MenuActions gameStatus={gameInfo?.status} isInGame={isInGame} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} />
            <div className='relative w-full h-[60%] p-3 bg-gray-900 border-4  mt-10 container rounded-2xl '>
                <div className=" rounded-lg w-full h-full  bg-green-900 border-4">
                    <div id='hilo_pot_money_box' className=' absolute top-1/4 left-1/2 transform -translate-x-1/2'>
                        {<GameResultComponent />}
                    </div>
                    {players.map((player, index) => {
                        return (
                            <div key={index} className={getClassName(index)}>
                                {player.joined == false ? <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                    <WaitingPlayer />
                                </div> : <Player isWaiting={true} player={player} />}
                            </div>
                        );

                    })}
                </div>


            </div>

            {<div className='w-full h-auto mt-5 container rounded-lg'>
                <div className='flex items-center justify-center h-full'>
                    <button onClick={handleJoinGame} role='button' className='bg-gradient-to-r from-[#FFD700] via-[#aa9414] to-[#FFD700] hover:bg-gradient-to-br focus:ring-4 focus:outline-none text-black py-2.5
     font-bold px-5 rounded-lg'>
                        <span className='text-black '>Press here to play/continue...</span>
                    </button>
                </div>
            </div>}

        </div>
    )
}

export default PlayAgain
