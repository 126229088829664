import React from 'react'

const ConfirmQuitLobbyModal = ({ handleClose, show, handleSubmit, message }) => {

    if (!show) {
        return null;
    }


    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-95">
            
            <div className="text-white rounded-3xl p-6 w-full max-w-md relative  bg-gray-700">
                <button
                    type="button"
                    onClick={handleClose}
                    className="absolute -top-4 -right-3 px-3 py-1 bg-gray-300 text-gray-700 rounded-3xl hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400"
                >
                    X
                </button>
                <div className='text-center'>
                    <h1 className='text-2xl'>Are you sure you want to quit?</h1>


                    <p className='mt-4 '>
                        {message ? message : 'If you quit now, you will lose the game.'}
                    </p>
                    <div className='flex gap-5 justify-center mt-5'>
                        <button onClick={handleClose} className='bg-gray-500 text-white px-5 py-2 rounded-xl'>No</button>
                        <button onClick={handleSubmit} className='bg-gray-900 text-white px-5 py-2 rounded-xl'>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ConfirmQuitLobbyModal
