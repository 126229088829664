import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import Card from "../../PokerTest/Components/Pages/Cards/index"
import { useSelector } from 'react-redux'
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
const Player = ({ player, gameResult }) => {
  const userData = useSelector(state => state.auth.userData)

  const highLightCard = (card) => {
    if (!gameResult) return false;
    if (gameResult?.isAllIn) return true
    // const { winner, winnerCombination } = gameResult;
    // if (!winnerCombination) return false;
    // const isExists = winnerCombination['Cards'].filter(item => item['Suit'] === card['Suit'] && item['Rank'] === card['Rank'])
    return true

  }


  const RenderGameAmount = ({ amount, type }) => {
    if (!amount) return null;

    let newAmount = Number(amount).toFixed(2)

    if (type === 'withdrawal') {
      return <span className="text-red-600 text-xs font-extrabold"> - {newAmount} Sol</span>
    } else if (type === 'deposit') {
      let playerAmount = Number(player.amount).toFixed(2)
      let restAmount = Number(player.restAmount).toFixed(2)

      let totalAmount = Number(restAmount) - Number(playerAmount)
      totalAmount = totalAmount.toFixed(2)
      totalAmount = Number(totalAmount)
      
      totalAmount = Math.abs(totalAmount).toFixed(2)

      if (player.userId == userData?.id) return <span className="text-green-500 text-xs font-extrabold"> ({newAmount} + <span className=''>{totalAmount}</span>) Sol </span>
      
      return <span className="text-green-500 text-xs font-extrabold"> + {newAmount} Sol </span>

    }
  }


  const RenderWinningHands = ({ player }) => {
    if (!gameResult) return null;
    const { winningHands } = gameResult;
    if (!winningHands) return null;

    const playerRank = winningHands.find(item => item['userId'] === player?.userId)
    const playerRankIndex = winningHands.findIndex(item => item['userId'] === player?.userId)
    if (!playerRank) return null;
    return <span className="text-xs font-semibold px-2 py-1">{playerRankIndex + 1} {playerRank['rank']}</span>
  }

  return (
    <div className={`relative flex items-center rounded-lg space-x-4 border-white `}>
      {/*  */}
      <div className='relative flex flex-col items-center '>


        <div className='w-full justify-center  flex items-center z-20 gap-1'>
          {!player.isFolded && !player.isLeft && player['Hand'].map((card, index) => {
            return <Card key={index} card={card} className={`w-12 h-16 rounded-sm ${!gameResult?.isAllIn ? highLightCard(card) ? '' : '' : ''}`} />
          })}
          {player.isFolded && !player.isLeft && <img src={FoldImage} className='block  mx-auto w-full h-16' />}
          {player.isLeft && !player.isFolded && <img src={LeftImage} className='block  mx-auto w-full h-16' />}
        </div>

        <div className={`p-2 ${player.index == 1 && 'bg-green-300 rounded-md' } rounded-md"}`}>
          <div className={`flex flex-row py-1.5 border-2 rounded-md shadow-lg bg-black px-3 z-20 items-center justify-between`} >
            {<img src={player?.profileUrl} className="w-8 h-8 rounded-full border-white border" />}
            {/* {player.isFolded && <img src={FoldImage} className="w-8 h-8 rounded-full border-white border" />} */}
            <div className='ml-2'>
              <div id={`poker_result_player_${player.userId}`} className="text-white font-semibold text-xs">{player.userName ? player.userName : player.address == userData.address ? 'You' : formatAddress(player.address, 6)}</div>
              <div className="text-blue-300 text-xs"><RenderGameAmount amount={player.totalAmount} type={player.type} />
              </div>
            </div>
          </div>
          <div className='w-full bg-purple-600 text-white px-2 flex items-center justify-center'>
            <span className='font-extrabold italic text-2xl'>{player.index} {player.index == 1 && <EmojiEventsIcon />}</span>
            <span className='text-xs font-semibold ml-1'>{player.rank}</span>
            <RenderWinningHands player={player} />
          </div>
        </div>

      </div>




    </div>
  )
}

export default Player
