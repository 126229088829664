import React, { useEffect, useState } from 'react'
import Dealer from "../../../../../../../assets/img/dealer.png"
import CurrentPlayer from '../../CurrentPlayer'
import { formatAddress } from '../../../../../../../Config/function'
import Fold from "../../../../../../../assets/img/fold.png"
import CardHeart from '../../CardHeart'
import CardDiamond from '../../CardDiamond'
import CardSpade from '../../CardSpade'
import CardClub from '../../CardClub'
import { useSelector } from 'react-redux'
const PokerPlayer = ({ showResult, gameResult, gameMoveStatus, player, socket, showDealer }) => {

    const solPrice = useSelector(state => state.auth.solPrice)
    const [playerMoveInfo, setPlayerMoveInfo] = useState(null)
    const userData = useSelector(state => state.auth.userData)

    const isMyCards = (player['address'] === userData.address)

    const foldedPlayers = gameMoveStatus?.foldedPlayers || []

    const isPlayerFold = (userId) => {
        if (gameResult) {
            const { winner, allFolded } = gameResult;
            if ((winner !== player.address) && allFolded) return true
        }
        const foldedUser = foldedPlayers.filter(item => item === userId)
        return foldedUser.length > 0
    }


    useEffect(() => {
        if (showResult) return;
        if (!socket) return;

        socket.on('userMove', (data) => {
            setPlayerMoveInfo(data)
            setTimeout(() => {
                setPlayerMoveInfo(null)
            }, 3000);
        })

        return () => {
            socket.off('userMove')
        }

    }, [])


    const RenderMove = ({ amount, action }) => {
        if (action === 'fold') {
            return <span>{action} </span>
        }
        if (action === 'check') {
            return <span>{action} </span>
        }

        if (action === 'raise') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'call') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'allin') {
            return <span>{action} {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'blind') {
            return <span>Blind {Number(amount).toFixed(2)} Sol</span>
        }

        if (action === 'big_blind') {
            return <span>Big Blind {Number(amount).toFixed(2)} Sol</span>
        }
        if (action === 'bet') {
            return <span>Bet {Number(amount).toFixed(2)} Sol</span>
        }

        return null

    }

    const RenderDealer = () => {
        return <div>
            <img className='absolute -right-5 -bottom-5 h-8 w-8 shadow-2xl rounded-circle z-10 ' src={Dealer} alt='dealer' />
        </div>
    }
    const RenderBlind = () => {
        return <div className='absolute -top-1/4 -left-6 text-xs flex justify-center items-center text-white font-bold bg-red-800  h-8 w-auto px-2 shadow-2xl rounded-md z-10 '>
            Small Blind
        </div>
    }
    const RenderBigBlind = () => {
        return <div className='absolute -top-1/4 -left-6 text-xs flex justify-center items-center text-white font-bold bg-red-800  h-8 w-auto px-2 shadow-2xl rounded-md z-10 '>
            Big Blind
        </div>
    }

    const RenderTimer = () => {
        const [timer, setTimer] = useState(gameMoveStatus?.timer || 30)

        useEffect(() => {
            const interval = setInterval(() => {
                setTimer(timer => timer - 1)
            }, 1000)

            if (timer === 0) {
                clearInterval(interval)
            }

            return () => {
                clearInterval(interval)
            }
        }, [timer])

        if (showResult) return null
        // if (gameMoveStatus?.round === 0 && ['blind', 'big_blind'].includes(player.userRole) && gameMoveStatus?.userId == userData?.id) return null


        if (!gameMoveStatus.timer) return null

        if (gameMoveStatus?.userId !== player?.userId) return null

        return <div className='absolute -bottom-1/4 left-1/2 transform -translate-x-1/2 flex justify-center items-center text-white font-bold bg-red-800  h-8 w-8 shadow-2xl rounded-circle text-xs z-10 fade-in-up'>
            {timer}
        </div>
    }

    const highLightCard = (card) => {
        if (!showResult) return false;
        if (!gameResult) return false;
        const { winner, winnerCombination } = gameResult;
        if (!winnerCombination) return false;
        const isExists = winnerCombination['Cards'].filter(item => item['Suit'] === card['Suit'] && item['Rank'] === card['Rank'])
        return isExists.length > 0 ? 'scale-150 mx-4' : 'bg_transparent_dark';
    }


    const RenderCard = ({ card, className }) => {
        const suit = card['Suit']
        const rank = card['Rank']

        if (suit === 'H') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardHeart playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'D') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardDiamond playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'S') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardSpade playerCard={true} rank={rank} className={className} /></div>
        }
        if (suit === 'C') {
            return <div className={`${highLightCard(card)} w-16 h-20`}><CardClub playerCard={true} rank={rank} className={className} /></div>
        }

        return null
    }


    const highlightPlayer = () => {
        if (!showResult) return false;
        if (!gameResult) return false;
        const { winner, winnerCombination } = gameResult;
        if (!winner) return false;
        const isWinner = (winner === player?.address);
        return !isWinner ? 'bg_transparent_dark' : false;
    }

    const RenderUsd = ({ amount }) => {
        if (!solPrice) return null
        if (!amount) return null
        let newAmount = Number(amount)

        return <span className='text-sm text-white font-bold ms-2'>({(solPrice * newAmount).toFixed(2)} USD)</span>
    }

    let playersBalance = gameMoveStatus?.playersBalance || []
    let playerBalance = playersBalance.find(item => item.userId === player.userId)

    return (

        <>
            {!isMyCards && <div className={`relative flex items-center bg-green-900 px-1 py-2 border-2  rounded-lg shadow-lg space-x-4 ${((gameMoveStatus?.userId === player?.userId) && (gameMoveStatus.betStatus === 'playing')) ? 'border-red-800 ' : 'border-white'} ${highlightPlayer()}`}>
                {showDealer && <RenderDealer />}
                {player?.userRole == 'blind' && <RenderBlind />}
                {player?.userRole == 'big_blind' && <RenderBigBlind />}
                {gameMoveStatus.betStatus === 'playing' && <RenderTimer />}
                <div className="relative">
                    <img src={player?.profileUrl} className="w-12 h-12 rounded-full border-white border" />
                </div>
                <div>
                    <div className="text-white font-semibold text-xs">{player.userName ? player.userName : formatAddress(player.address, 6)}</div>
                    <div className="text-blue-300 text-xs">
                        {/* {playerBalance?.amount} Sol */}
                        {/* <RenderUsd amount={playerBalance?.amount} /> */}
                        *** Sol
                        (*** USD)
                    </div>
                </div>
                {(showResult && !isPlayerFold(player?.userId)) ? <div className='w-max absolute -top-2/3 right-1/2 translate-x-1/2 flex justify-center'>
                    {player['Hand'].map((card, index) => {
                        return <RenderCard key={index} card={card} className={'w-16 h-20'} />
                    })}
                </div> : !isPlayerFold(player?.userId) && (gameMoveStatus.betStatus == 'playing') && <div className="absolute ml-auto -top-2/3 right-1/4">
                    <div className="relative w-10 h-16">
                        <div className="fade-in-up absolute top-0 left-0 w-full h-full bg-red-300 rounded-md shadow-md"></div>
                        <div className="fade-in-up   absolute top-2 left-2 w-full h-full bg-pink-300 rounded-md shadow-md"></div>
                    </div>
                </div>}
                {isPlayerFold(player?.userId) && <div className="absolute ml-auto -top-2/3 right-1/4">
                    <div className="relative w-60 h-16">
                        <div className="absolute top-0 left-1/2 w-full h-full rounded-md shadow-md">
                            {<img src={Fold} alt="card" style={{ height: '50px', }} />}
                        </div>
                    </div>
                </div>}
                {(playerMoveInfo?.userId == player?.userId) && <div class="absolute -bottom-3 px-3 left-1/2 transform -translate-x-1/2 scale-up text-capitalize capitalize text-black text-center mt-3 bg-white border border-black rounded-xl ">
                    <p class="text-black text-center font-bold text-xs"><RenderMove amount={playerMoveInfo?.amount} action={playerMoveInfo?.action} /></p>
                </div>}
            </div>}
            {isMyCards && <CurrentPlayer gameResult={gameResult} showResult={showResult} showDealer={showDealer} player={player} gameMoveStatus={gameMoveStatus} isPlayerFold={isPlayerFold} socket={socket} />}
        </>
    )
}

export default PokerPlayer



