import React, { useState } from 'react'

import { useNavigate, useParams } from 'react-router-dom'
import { Slide, toast } from 'react-toastify';
import ToastContent from '../../../../../CommonComponent/Toast';
import axiosInstance from '../../../../../Config/axios';

import Player from './Player';
import StartGameAction from './StartGameAction';
import MenuActions from './MenuActions';
import hotToast from 'react-hot-toast';
import Loader from '../../../../ChatingComponent/loader';

const WaitingPlayers = ({ gameInfo, fetchPlayers, players }) => {

  const gameCreated = gameInfo?.createdBy
  const navigate = useNavigate()
  const newPlayers = players

  let joinedPlayers = newPlayers


  const { gameAddress } = useParams()
  const [isLoading, setIsLoading] = useState(false)

  const userData = JSON.parse(localStorage.getItem('userData'))

  const isInGame = newPlayers.filter(player => player.address == userData?.address).length > 0


  const handleJoinGame = async () => {


    try {
      if (gameInfo.status === 'started') {
        // toast.error(<ToastContent status='error' message='Game already started' />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // });
        hotToast.error('Game already started', { duration: 5000, position: 'bottom-center' })

        fetchPlayers()
        return
      }

      setIsLoading(true)
      const bodyData = { gameAddress: gameAddress }
      const response = await axiosInstance.post('/api/game/poker/join', bodyData)

      const { data: responseData } = response

      if (!responseData.status) {
        setIsLoading(false)
        // return toast.error(<ToastContent status='error' message={responseData.message} />, {
        //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
        // })
        return hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
      }
      setIsLoading(false)
      // toast.success(<ToastContent status='success' message={responseData.message} />, {
      //   transition: Slide, autoClose: 2000, position: 'bottom-center', theme: 'dark'
      // })
      hotToast.success(responseData.message, { duration: 5000, position: 'bottom-center' })

      const data = { slug: 'poker', gameAddress: gameAddress }
      localStorage.setItem('currentJoinedGame', JSON.stringify(data))

      fetchPlayers()
    } catch (error) {
      setIsLoading(false)
      hotToast.error('Error joining game', { duration: 5000, position: 'bottom-center' })
    }
  }



  let isJoined = false
  const currentUser = players.find(player => player.address == userData?.address)
  if (currentUser && currentUser.status == 'pending') {
    isJoined = true
  }

  if (isJoined) {
    const myProfile = players.find(player => player.address == userData?.address)
    const otherPlayers = players.filter(player => player.address != userData?.address)

    joinedPlayers = [myProfile, ...otherPlayers]
  }

  let gameCanStart = false
  const totalPlayers = joinedPlayers.length
  if (totalPlayers >= gameInfo?.minPlayers) {
    gameCanStart = true
  }

  let allPlayers = []
  const myProfile = joinedPlayers.find(player => player.address == userData?.address)
  if (myProfile) {
    allPlayers = [myProfile, ...joinedPlayers.filter(player => player.address != userData?.address)]
  } else {
    allPlayers = joinedPlayers
  }

  const getClassName = (index) => {
    let className = 'absolute flex flex-col items-center top-5 left-5'

    // always show the user at the bottom and the rest of the players in a circular manner

    if (allPlayers.length == 1) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }

    } else if (allPlayers.length == 2) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2'
      }
    } else if (allPlayers.length == 3) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 2) {
        return 'absolute flex flex-col items-center right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
      }
    } else if (allPlayers.length == 4) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center left-0 top-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center left-1/2 top-0 transform -translate-x-1/2 -translate-y-1/2'
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center right-0 top-1/2 transform translate-x-1/2 -translate-y-1/2'
      }
    } else if (allPlayers.length == 5) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    } else if (allPlayers.length == 6) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center -top-10 left-1/2 transform -translate-x-1/2 -translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
      if (index == 5) {
        return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    } else if (allPlayers.length == 7) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 '
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
      if (index == 5) {
        return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
      }
      if (index == 6) {
        return 'absolute flex flex-col items-center bottom-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    } else if (allPlayers.length == 8) {
      if (index == 0) {
        return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      }
      if (index == 1) {
        return 'absolute flex flex-col items-center bottom-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 2) {
        return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 '
      }

      if (index == 3) {
        return 'absolute flex flex-col items-center top-0 left-0 transform -translate-x-1/2 translate-y-1/4'
      }

      if (index == 4) {
        return 'absolute flex flex-col items-center -top-10 left-1/2 transform -translate-x-1/2'
      }
      if (index == 5) {
        return 'absolute flex flex-col items-center top-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
      if (index == 6) {
        return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
      }
      if (index == 7) {
        return 'absolute flex flex-col items-center bottom-0 right-0 transform translate-x-1/2 translate-y-1/4'
      }
    }




    return className
  }

  const enableFullScreen = () => {
    try {

      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else {
          hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
        }
        return;
      }

      const element = document.getElementById('poker_waiting_table');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else {
        hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
      }
    } catch (error) {
      hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
    }
  }

  const handleLeaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/poker/leave', { gameAddress })
      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message, { duration: 5000, position: 'bottom-center' })
      } else {
        navigate('/all-game-page');
      }

    } catch (error) {
      hotToast.error('Error leaving game', { duration: 5000, position: 'bottom-center' })
    }
  }

  return (
    <div id='poker_waiting_table'>

      <MenuActions isInGame={isInGame} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} />
      <div className=" poker_table container px-5 h-2/3 rounded-lg shadow-lg top-1/2 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 ">

        <div className=" p-3 border-2 shadow-in h-[80%] w-full bg-green-900  rounded-full"
        >
          <div className=" relative gap-4 p-4 border-2 h-full w-full  rounded-full bg-gradient-to-r from-emerald-800 to-emerald-900">
            <div id='dealer_target' className='absolute top-1/2 left-1/2 transform -translate-x-1/4 -translate-y-1/4'>

            </div>

            {allPlayers.map((player, index) => {
              return (
                <div id={`player_${player?.userId}`} className={`${getClassName(index, player.userId)}`} >
                  <Player index={index} player={player} />
                </div>
              )
            })}

          </div>

        </div>
        {gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full'>
            {isInGame && !gameCanStart && (gameCreated === userData?.id) && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!isInGame && (
              <button
                onClick={handleJoinGame}
                disabled={isLoading}
                className='bg-yellow-500 text-black flex flex-row items-center justify-between font-bold px-4 py-1 rounded-lg shadow-lg'
              >
                {isLoading && <Loader className='h-5 w-5 rounded-full mr-2 text-black border-black' />} {isLoading ? 'Joining...' : 'Join Game'}
              </button>
            )}
            {myProfile && gameCanStart && (gameCreated === userData?.id
              ? <StartGameAction fetchGameInfo={fetchPlayers} />
              : <span className='text-white font-bold '>
                {`Waiting to start the game`}
              </span>
            )}
          </div>
        </div>}
        {!gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full'>
            {isInGame && !gameCanStart && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!isInGame && (
              <button
                onClick={handleJoinGame}
                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
              >
                Join Game <Loader />
              </button>
            )}
            {gameCanStart && <StartGameAction fetchGameInfo={fetchPlayers} />}
          </div>
        </div>}



      </div>
    </div>
  )
}

export default WaitingPlayers