import React, { useEffect, useState } from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'

const Player = ({ player }) => {

  return (
    <div className='hi-lo-player flex items-center flex-col	'>

      {!player.isFolded && player.isLeft && <img src={LeftImage} alt='profile' className={`hi-lo- h-20 w-40 p-1/2 `} />}
      {player.isFolded && !player.isLeft && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-40 p-1/2 `} />}
      {!player.isFolded && !player.isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 borde`} />}



      <div className={`bg-black text-white`}>
        <div  className={`flex flex-col items-center justify-center  font-extrabold px-5 text-center border-2 rounded-md `}>
          <div id={`high_low_result_player_${player.userId}`} className='name mb-1 text-sm'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>

          <div className='relative flex flex-row items-center justify-center'>
            {/* {player.userId === userData?.id ? <span className='text-xs'>{(player.amount).toFixed(2)} Sol</span> : <span className='text-xs'>***** Sol</span>} */}
            {/* {player.userId === userData?.id ? (<span className='text-xs ml-1'>
              (<SolToUSD amount={player.amount} symbol='USD' />)
            </span>) : <span className='text-xs ml-1'>
              (***** USD)
            </span>} */}
          </div>

        </div>
      </div>

    </div>
  )
}

export default Player
