import React from 'react'
import MenuActions from '../Common/MenuActions'
import Player from './Player'
import { useSelector } from 'react-redux'
import hotToast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import axiosInstance from '../../../../../Config/axios'
import StartGameAction from './StartGameActions'

const JoinUsers = ({ gameInfo, fetchGameInfo }) => {
  const { gameAddress } = useParams()
  const navigate = useNavigate()
  const userData = useSelector(state => state.auth.userData)


  const allPlayers = gameInfo?.players || []
  const minPlayers = gameInfo?.minPlayers

  const myProfile = allPlayers.find(player => player.userId === userData.id)
  let allNewPlayers = []
  if (myProfile) {
    const otherPlayers = allPlayers.filter(player => player.userId !== userData.id)
    allNewPlayers = [myProfile, ...otherPlayers]
  } else {
    allNewPlayers = allPlayers
  }

  const getClassName = (index) => {
    let className = 'absolute flex flex-col items-center top-5 left-5'

    if (allNewPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

    if (allNewPlayers.length == 2) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

      if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

    } else if (allNewPlayers.length == 3) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

    } else if (allNewPlayers.length == 4) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
    } else if (allNewPlayers.length == 5) {

      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  translate-x-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-x-1/2'
      if (index == 4) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

    } else if (allNewPlayers.length == 6) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
      if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
      if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

    } else if (allNewPlayers.length == 7) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
      if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
      if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
    } else if (allNewPlayers.length == 8) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
      if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
      if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

    } else if (allNewPlayers.length == 9) {
      if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
      if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
      if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
      if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
      if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
      if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
      if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
      if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
    }
    return className
  }


  const handleJoinGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/uno-card-game/join', { gameAddress })


      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message)
      } else {
        const data = { slug: 'uno-card-game', gameAddress }
        localStorage.setItem('currentJoinedGame', JSON.stringify(data))
        hotToast.success('Joined', { position: 'bottom-center' })
      }

    } catch (error) {
      hotToast.error('Error in joining game');
    }
  }

  const handleLeaveGame = async () => {
    try {
      const response = await axiosInstance.post('/api/game/uno-card-game/leave', { gameAddress })
      const { data: responseData } = response

      if (!responseData.status) {
        hotToast.error(responseData.message)
      } else {
        navigate('/all-game-page')
      }

    } catch (error) {
      hotToast.error('Error in leaving game')
    }
  }

  const enableFullScreen = () => {
    try {

      let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

      if (isFullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) { /* Safari */
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) { /* IE11 */
          document.msExitFullscreen();
        } else if (document.mozCancelFullScreen) { /* Firefox */
          document.mozCancelFullScreen();
        } else {
          hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
        }
        return;
      }

      const element = document.getElementById('uno_card__screen');
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) { /* Safari */
        element.webkitRequestFullscreen();
      } else if (element.msRequestFullscreen) { /* IE11 */
        element.msRequestFullscreen();
      } else if (element.mozRequestFullScreen) { /* Firefox */
        element.mozRequestFullScreen();
      } else {
        hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
      }
    } catch (error) {
      hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
    }
  }
  const minPlayersJoined = allNewPlayers.length >= minPlayers


  return (
    <div id='uno_card__screen' className='relative game  flex flex-col items-center h-full'>
      <MenuActions isInGame={myProfile} handleJoinGame={handleJoinGame} handleLeaveGame={handleLeaveGame} enableFullScreen={enableFullScreen} gameInfo={gameInfo}/>

      <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-5 container rounded-full '>
        <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

          <div className="  w-full h-full rounded-full border-2" style={{
            backgroundColor: '#222222'
          }}>
            <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            {!myProfile && <button onClick={handleJoinGame} className='text-xs bg-white text-black font-extrabold px-5 py-2 btn text-center mt-5 p-2 rounded-lg'>
                                Join Game
                            </button>}
            </div>
            {allNewPlayers.map((player, index) => {
              return (
                <div key={index} className={getClassName(index)}>
                  <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                    <Player player={player} />
                  </div>
                </div>
              );

            })}
          </div>
        </div>
      </div>

      {gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full'>
            {myProfile && !minPlayersJoined && (gameInfo?.createdBy === userData?.id) && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!myProfile && (
              <button
                onClick={handleJoinGame}
                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
              >
                Join Game
              </button>
            )}
            {myProfile && minPlayersJoined && (gameInfo?.createdBy === userData?.id
                ? <StartGameAction fetchGameInfo={fetchGameInfo} />
                : <span className='text-white font-bold '>
                  {`Waiting to start the game`}
                </span>
            )}
            {myProfile && !minPlayersJoined && (gameInfo?.createdBy !== userData?.id) && <span className='text-white font-bold '> 
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
              </span>}
          </div>
        </div>}

        {!gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
          <div className='flex items-center justify-center h-full'>
            {myProfile && !minPlayersJoined && <span className='text-white font-bold text-md'>
              {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
            </span>}
            {!myProfile && (
              <button
                onClick={handleJoinGame}
                className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
              >
                Join Game
              </button>
            )}
            {minPlayersJoined && <StartGameAction fetchGameInfo={fetchGameInfo} />}
          </div>
        </div>}
    </div>
  )
}

export default JoinUsers
