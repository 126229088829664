import React from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ConfirmQuitLobbyModal from '../../../../../../../CommonComponent/ConfirmQuitLobbyModal';

const QuitLobby = ({ handleQuitLobby, message }) => {
    const [showQuitModal, setShowQuitModal] = React.useState(false)

    return (
        <>
        <button onClick={() => setShowQuitModal(!showQuitModal)} className='text-white text-xs py-2 px-5  border-2 rounded-xl bg-gray-900' >
            <ArrowBackIcon fontSize='16' className='me-5' /> Quit to lobby
        </button>
        {showQuitModal && <ConfirmQuitLobbyModal message={message} show={showQuitModal} handleClose={() => setShowQuitModal(false)} handleSubmit={handleQuitLobby} />}
        </>

    )
}

export default QuitLobby
