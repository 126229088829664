import React from 'react'
import MenuActions from '../MenuActions'
import Player from './Player'
import { useSelector } from 'react-redux'
import { Slide, toast } from 'react-toastify'
import ToastContent from '../../../../../CommonComponent/Toast'
import axiosInstance from "../../../../../Config/axios"
import hotToast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import StartGameAction from "./StartGameAction"

const WaitingForPlayers = ({ gameInfo, socket, fetchGameInfo }) => {

    const userData = useSelector(state => state.auth.userData)

    const navigate = useNavigate()

    const joinedPlayers = gameInfo?.players || []
    const minPlayers = gameInfo?.minPlayers
    const createdBy = gameInfo?.createdBy

    const myProfile = joinedPlayers.find(player => player.userId == userData?.id)
    const otherPlayers = joinedPlayers.filter(player => player.userId != userData?.id)


    let allPlayers = []
    if (myProfile) {
        allPlayers = [myProfile, ...otherPlayers]
    } else {
        allPlayers = otherPlayers
    }


    const getClassName = (index) => {
        let className = 'absolute flex flex-col items-center top-5 left-5'

        if (allPlayers.length == 1) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        if (allPlayers.length == 2) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

            if (index == 1) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 3) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'

        } else if (allPlayers.length == 4) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
        } else if (allPlayers.length == 5) {

            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-1/4 left-0 transform -translate-x-1/2 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 right-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center bottom-1/4 right-0 transform translate-x-1/2 translate-y-1/2'

        } else if (allPlayers.length == 6) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 '
            if (index == 2) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2'
            if (index == 5) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2'

        } else if (allPlayers.length == 7) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/2 translate-y-1/4'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform translate-x-1/2 -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 5) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/2 translate-y-1/4'
        } else if (allPlayers.length == 8) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 6) return 'absolute flex flex-col items-center top-1/2 right-0 transform -translate-y-1/2 translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center -bottom-0 right-0 transform -translate-x-1/4 translate-y-1/2 '

        } else if (allPlayers.length == 9) {
            if (index == 0) return 'absolute flex flex-col items-center -bottom-20 left-1/2 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 1) return 'absolute flex flex-col items-center bottom-0 left-0 transform translate-x-1/4 translate-y-1/2'
            if (index == 2) return 'absolute flex flex-col items-center top-1/2 left-0 transform -translate-x-1/2 -translate-y-1/4'
            if (index == 3) return 'absolute flex flex-col items-center top-0 left-0 transform  -translate-y-1/4'
            if (index == 4) return 'absolute flex flex-col items-center top-0 left-1/4 transform translate-x-1/2 -translate-y-1/2'
            if (index == 5) return 'absolute flex flex-col items-center top-0 right-1/4 transform -translate-x-1/2 -translate-y-1/2'
            if (index == 6) return 'absolute flex flex-col items-center top-0 right-0 transform -translate-y-1/4 -translate-x-1/2'
            if (index == 7) return 'absolute flex flex-col items-center top-1/2 right-0 transform translate-x-1/4 -translate-y-1/2 '
            if (index == 8) return 'absolute flex flex-col items-center bottom-0 right-0 transform -translate-x-1/4 translate-y-1/4'
        }
        return className
    }


    const handleJoinGame = async () => {
        try {
            const bodyData = { gameAddress: gameInfo.gameAddress }
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/join', bodyData)

            const { data: responseData } = response
            if (!responseData.status) {
                toast.error(<ToastContent title="Error" message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, position: 'bottom-center'
                })
            } else {
                const data = { slug: 'seven-twenty-seven', gameAddress: gameInfo.gameAddress }
                localStorage.setItem('currentJoinedGame', JSON.stringify(data))
                hotToast.success('Joined game successfully', { duration: 2000, position: 'bottom-center' })
                if (socket) {
                    socket.emit('joinedGame', { userId: userData?.id, roomId: gameInfo.gameAddress })
                }
            }

        } catch (error) {
            toast.error(<ToastContent message={error.message} />, { position: "top-right", autoClose: 5000, transition: Slide })
        }
    }


    const enableFullScreen = () => {
        try {

            let isFullScreen = document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement || document.msFullscreenElement;

            if (isFullScreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) { /* Safari */
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) { /* IE11 */
                    document.msExitFullscreen();
                } else if (document.mozCancelFullScreen) { /* Firefox */
                    document.mozCancelFullScreen();
                } else {
                    hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
                }
                return;
            }

            const element = document.getElementById('seven_tws_screen');
            if (element.requestFullscreen) {
                element.requestFullscreen();
            } else if (element.webkitRequestFullscreen) { /* Safari */
                element.webkitRequestFullscreen();
            } else if (element.msRequestFullscreen) { /* IE11 */
                element.msRequestFullscreen();
            } else if (element.mozRequestFullScreen) { /* Firefox */
                element.mozRequestFullScreen();
            } else {
                hotToast.error('Fullscreen is not supported', { duration: 5000, position: 'bottom-center' })
            }
        } catch (error) {
            hotToast.error('Failed to enable full screen', { duration: 5000, position: 'bottom-center' })
        }
    }


    const handleLeaveGame = async () => {
        try {
            const response = await axiosInstance.post('/api/game/seven-twenty-seven/leave', { gameAddress: gameInfo.gameAddress })
            const { data: responseData } = response

            if (!responseData.status) {
                toast.error(<ToastContent title='Error' message={responseData.message} />, {
                    transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
                })
            } else {
                navigate('/all-game-page');
            }

        } catch (error) {
            toast.error(<ToastContent title='Error' message={error.message} />, {
                transition: Slide, autoClose: 3000, hideProgressBar: true, closeOnClick: true, position: 'bottom-center'
            })
        }
    }


    const minPlayersJoined = allPlayers.length >= minPlayers

    return (
        <div id='seven_tws_screen' className='relative game  flex flex-col items-center h-full'>
            <MenuActions isInGame={myProfile} handleJoinGame={handleJoinGame} enableFullScreen={enableFullScreen} handleLeaveGame={handleLeaveGame} gameInfo={gameInfo} />
            <div className='w-full h-[70%] p-1 bg-gray-200 border-2  mt-20 container rounded-full '>
                <div className="relative p-3 w-full h-full rounded-full border-2 bg-slate-900">

                    <div className="  w-full h-full rounded-full border-2" style={{
                        backgroundColor: '#222222'
                    }}>
                        <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
                            {!myProfile && <button onClick={handleJoinGame} className='text-xs bg-white text-black font-extrabold px-5 py-2 btn text-center mt-5 p-2 rounded-lg'>
                                Join Game
                            </button>}

                        </div>
                        {allPlayers.map((player, index) => {
                            return (
                                <div key={index} className={getClassName(index)}>
                                    <div className='hi-lo-player flex items-center flex-col shadow-2xl	'>
                                        <Player isWaiting={true} player={player} />
                                    </div>
                                </div>
                            );

                        })}
                    </div>
                </div>
            </div>
            {gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
                <div className='flex items-center justify-center h-full'>
                    {myProfile && !minPlayersJoined && (gameInfo?.createdBy === userData?.id) && <span className='text-white font-bold text-md'>
                        {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
                    </span>}
                    {!myProfile && (
                        <button
                            onClick={handleJoinGame}
                            className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
                        >
                            Join Game
                        </button>
                    )}
                    {myProfile && minPlayersJoined && (gameInfo?.createdBy === userData?.id
                        ? <StartGameAction fetchGameInfo={fetchGameInfo} />
                        : <span className='text-white font-bold '>
                            {`Waiting to start the game`}
                        </span>
                    )}
                </div>
            </div>}

            {!gameInfo?.isPrivate && <div className='w-full h-auto mt-5 container rounded-lg'>
                <div className='flex items-center justify-center h-full'>
                    {myProfile && !minPlayersJoined && <span className='text-white font-bold text-md'>
                        {`Game can be started when there are minimum ${gameInfo?.minPlayers} players`}
                    </span>}
                    {!myProfile && (
                        <button
                            onClick={handleJoinGame}
                            className='bg-yellow-500 text-black font-bold px-4 py-1 rounded-lg shadow-lg'
                        >
                            Join Game
                        </button>
                    )}
                    {minPlayersJoined && <StartGameAction fetchGameInfo={fetchGameInfo} />}
                </div>
            </div>}
        </div>
    )
}

export default WaitingForPlayers
