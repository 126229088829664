import React from 'react'
import Card2 from "../../../../../../assets/img/crd2.png"

const CardSpade = ({ rank, className, playerCard }) => {
  return (
    <>
      <div className={`pvp05 ${className && className}`}>
        <span className='z-10'>{rank}</span>
        <img className={`${playerCard ? 'w-20 h-28' : ' h-32 w-24'} fade-in-up`} src={Card2} alt="card" /></div>
    </>
  )
}

export default CardSpade