import React from 'react'
import Card from '../../Cards/index'

const CommunityCards = ({ showResult, card, gameResult }) => {


  const highlightCard = (card) => {
    if (!showResult) return false
    if (!gameResult) return false;
    if (gameResult?.isAllIn) return false
    // const { winnerCombination } = gameResult
    // const isWinnerCard = winnerCombination?.['Cards'].filter(winnerCard => winnerCard.Rank === card.Rank && winnerCard.Suit === card.Suit)

    // if (isWinnerCard?.length > 0) return false
    return false
  }



  return (
    <div className='community_cards items-center flex flex-row justify-center gap-2'>
      {card.map((card, index) => {
        return <div key={index} className={`fade-in-up rounded-md ${highlightCard(card) ? 'bg_transparent_dark' : showResult ? '' : ''}`}>
          <Card card={card} className={'h-20 w-14 rounded-sm '} />
        </div>
      })}
    </div>
  )

}

export default CommunityCards