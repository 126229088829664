import React from 'react'
import { formatAddress } from '../../../../../Config/function'
import FoldImage from '../../../../../assets/img/fold.png'
import LeftImage from '../../../../../assets/img/left.png'
import { useSelector } from 'react-redux'
import Card from "../Common/Cards/index"
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

const Player = ({ player, winner, totalPlayers, index, gameResult }) => {
    const userData = useSelector(state => state.auth.userData)
    const getCardsClassName = (index) => {
        if (totalPlayers === 2) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
        }
        if (totalPlayers === 3) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute left-16 top-0 rotate-90'
            if (index === 2) return 'absolute right-16 rotate-90 top-0'
        }
        if (totalPlayers === 4) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute left-16 top-0 rotate-90'
            if (index === 2) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
            if (index === 3) return 'absolute right-16 rotate-90 top-0'
        }

        if (totalPlayers === 5) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
            if (index === 2) return 'absolute  top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
            if (index === 3) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
            if (index === 4) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        }

        if (totalPlayers === 6) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
            if (index === 2) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
            if (index === 3) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
            if (index === 4) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
            if (index === 5) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        }
        if (totalPlayers === 7) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
            if (index === 2) return 'absolute left-16 top-1/2 rotate-90'
            if (index === 3) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
            if (index === 4) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
            if (index === 5) return 'absolute right-16 top-1/2 rotate-90'
            if (index === 6) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        }
        if (totalPlayers === 8) {
            if (index === 0) return 'absolute -top-20 left-1/2 transform -translate-x-1/2'
            if (index === 1) return 'absolute bottom-1/2 left-1/2 translate-x-1/4 -translate-y-1/4'
            if (index === 2) return 'absolute left-16 top-1/2 rotate-90'
            if (index === 3) return 'absolute top-1/2 left-1/2  translate-y-1/2 translate-x-1/4 '
            if (index === 4) return 'absolute -bottom-20 left-1/2 transform -translate-x-1/2'
            if (index === 5) return 'absolute  top-1/2 right-1/2  translate-y-1/2 -translate-x-1/4 '
            if (index === 6) return 'absolute right-16 top-1/2 rotate-90'
            if (index === 7) return 'absolute bottom-1/2 right-1/2 -translate-y-1/4 -translate-x-1/4'
        }



    }

    const highLightWinner = (userId) => {
        if (!winner) return false
        if (winner && winner.length === 0) return false
        const winnersUserId = winner?.map(winner => winner.userId)
        return winnersUserId.includes(userId)
    }

    const winnerCardHand = () => {
        if (player.isFolded) return false
        if (player.isLeft) return false
        if (gameResult['isAllIn']) {
            
            const { winningHands } = gameResult
            if (!winningHands) return false

            const winnerPlayer = winningHands.find(winner => winner.userId === player.userId)

            const winningHandsIndex = winningHands.findIndex(winner => winner.userId === player.userId)
            if (winningHandsIndex === -1) return false

            if (!winnerPlayer) return false
            if (player.type == 'deposit') {
                return <span> 1 <EmojiEventsIcon className='mr-1' /> {winnerPlayer?.rank}</span>
            }
            return winnerPlayer?.rank ? `${winningHandsIndex + 1} ${winnerPlayer?.rank}` : false
        }

        const { winningHands } = gameResult
        if (!winningHands) return false

        const winnerPlayer = winningHands.find(winner => winner.userId === player.userId)
        if (!winnerPlayer) return false
        const winningHandsIndex = winningHands.findIndex(winner => winner.userId === player.userId)
        if (winningHandsIndex === -1) return false

        if (player.type == 'deposit') {
            return <span> 1 <EmojiEventsIcon className='mr-1' /> {winnerPlayer?.rank}</span>
        }

        return winnerPlayer?.rank ? `${winningHandsIndex + 1} ${winnerPlayer?.rank}` : false

        // if (!highLightWinner(player.userId)) return false;
        // const winnerPlayer = winner.find(winner => winner.userId === player.userId)
        // return winnerPlayer?.rank ? winnerPlayer?.rank : false
    }

    const Cards = () => {

        const allCards = player.cards || []

        return allCards.map((card, index) => (
            <div className='flex flex-col items-center justify-center z-10'>
                <Card textSize={'text-xs'} fontSize={12} className={`z-20 h-16 w-10 rounded-sm ${highLightWinner(player.userId) ? "border-2 border-purple-400 shadow-purple-200 shadow-2xl rounded-md" : ""} rounded-md`} key={index} card={card} index={index} />
            </div>
        ))
    }

    const RenderPlayerAmount = ({player}) => {
        let totalAmount = player['totalAmount']
        totalAmount = Number(totalAmount).toFixed(2)
        let type = player['type']

        if (type === 'deposit') {
            let playerAmount = player['amount']
            playerAmount = Number(playerAmount).toFixed(2)

            let playerRestAmount = player['restAmount']
            playerRestAmount = Number(playerRestAmount).toFixed(2)

            let newTotalAmount = playerRestAmount - playerAmount
            newTotalAmount = Number(newTotalAmount).toFixed(2)

            newTotalAmount = Math.abs(newTotalAmount).toFixed(2)
            if (player.userId === userData?.id) {
                return <span className='text-xs text-green-400 font-extrabold'>({totalAmount} + {newTotalAmount}) Sol</span>
            }
            return <span className='text-xs text-green-400 font-extrabold'> + {totalAmount} Sol</span>
        }
        if (type === 'withdrawal') {
            return <span className='text-xs text-red-500 font-extrabold'>- {totalAmount} Sol</span>
        }
    }


    return (
        <div className='relative hi-lo-player flex items-center flex-col	'>
            {!player.isFolded && !player.isLeft && <div className={` flex items-center justify-center gap-1 ${getCardsClassName(index)}`}>
                <Cards />
            </div>}
            {player.isFolded && <img src={FoldImage} alt='profile' className={`hi-lo-player__profile h-16 w-44  p-1/2 ${winner.userId == player.userId && ""}`} />}
            {player.isLeft && !player.isFolded && <img src={LeftImage} alt='profile' className={`hi-lo-player__profile  h-16 w-44  p-1/2 ${winner.userId == player.userId && ""}`} />}
            {/* {!player.isFolded && !player.isLeft && <img src={player.profileUrl} alt='profile' className={`hi-lo-player__profile rounded-full h-16 w-16 border border-white p-1/2 ${winner.userId == player.userId && ""}`} />} */}

            <div className={`flex flex-row items-start justify-between ${winner?.userId === player.userId ? "bg-green-400 text-black py-3 px-5" : 'bg-black text-white'} font-extrabold px-1 text-center border-2 py-1 rounded-md`}>
            {<img src={player.profileUrl} id={`five_card_poker_result_player_${player.userId}`}  alt='profile' className={`hi-lo-player__profile rounded-full h-10 w-10 border border-white p-1/2 ${winner.userId == player.userId && ""}`} />}
                
                <div className='flex flex-col items-start justify-center ml-2'>
                <div className='name mb-0 text-xs'>{player.userName ? player.userName : formatAddress(player.address, 3)}</div>
                {<RenderPlayerAmount player={player}/>}
                </div>
            </div>
            {!player.isFolded && !player.isLeft && <span className=' text-sm text-white font-bold -bottom-0 bg-purple-600 skew-x-12 py-0.5 px-2 w-max text-center'>
                    {winnerCardHand()}
                </span>}
        </div>
    )
}

export default Player
